import axios from "axios";

const axiosInterceptor = axios.create({
    baseURL: process.env.BACKEND_BASE_URL,
});

axiosInterceptor.interceptors.response.use((response) => {
    return response
}, async function (error) {
    if (error.response.status === 401 || error.response.status === 403) {
        window.location.reload(true)
    }
    return Promise.reject(error);
});

export default axiosInterceptor;