import React, {Component} from "react";
import SearchEngine from './SearchEngine';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import {Grid, Container} from "@material-ui/core";
import {getUserEngines} from "../../actions/auth";
import Bba from "./2ba";
import {initSocket} from '../InitWebSocket';
import EnhancedTable from './TableProducts';
import {withTranslation} from "react-i18next";
import Loading from '../common/Loading';

const useStyles = theme => {
    return ({
        root: {
            flexGrow: 1,
            padding: theme.spacing(2)
        },
        paper: {
            padding: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    })
};


class Dashboard extends Component {

    static propTypes = {
        userSearchEngines: PropTypes.array,
        getUserEngines: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired,
        search_results_2ba: PropTypes.object.isRequired,
        search_results_oci: PropTypes.object.isRequired,
        searching: PropTypes.bool.isRequired,
        search_string: PropTypes.string,
        initSocket: PropTypes.func.isRequired,
        websocket: PropTypes.object.isRequired
    };

    componentDidMount() {
        this.props.getUserEngines();
        if (!this.props.websocket.connected) {
            const wsProtocol = process.env.STAGE === 'dev' ? 'ws://' : 'wss://';
            this.props.initSocket(wsProtocol + process.env.WEBSOCKET_HOST + '/ws/');
        }
    }


    render() {
        const {user} = this.props.auth
        const {classes, t} = this.props;

        const searchParams = new URLSearchParams(window.location.search);
        const searchQuery = searchParams.get('query');

        if (user && user.parent_company && ['Supplier'].includes(user.parent_company.license)) {
            return <Container/>
        }

        return (
            <Container>
                <div className={classes.root}>
                    <SearchEngine searchString={searchQuery}/>
                </div>
                {this.props.searching ? <Loading/> :
                    <div className={classes.root}>
                        <Grid container spacing={0}>
                            {/*<Grid item xs={1}>*/}
                            {/*    <Paper>*/}
                            {/*        {userSearchEngines ? <UserEngines/> : ''}*/}
                            {/*    </Paper>*/}
                            {/*</Grid>*/}
                            <Grid item xs={12}>
                                {this.props.search_results_2ba !== {} && this.props.search_results_2ba.TotalFound !== 0 ?
                                    <Bba/> : ''}
                                {this.props.search_results_oci !== {} && this.props.search_results_oci.TotalFound !== 0 ?
                                    <EnhancedTable
                                        data={this.props.search_results_oci.Products}
                                        translation={t}
                                        oci={true}
                                    /> : ''}
                            </Grid>
                        </Grid>
                    </div>
                }
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.authReducer,
    search_results_2ba: state.esearchReducer.search_results_2ba,
    search_results_oci: state.esearchReducer.search_results_oci,
    search_string: state.esearchReducer.search_string,
    searching: state.esearchReducer.searching,
    userSearchEngines: state.authReducer.userSearchEngines,
    websocket: state.websocketReducer,
});

export default (withStyles(useStyles)(connect(mapStateToProps, {
    getUserEngines,
    initSocket
})(withTranslation(['common'])(Dashboard))));